import React from "react";
import Markdown from "react-markdown";

import about from "assets/images/about.jpg";
import "./page-about.scss";

import Grid from "components/grid/Grid";
import { ABOUT_DESCRIPTION } from "./page-about.constants";

const BLOCK = "page-about";

function PageAbout() {
  return (
    <Grid container className={BLOCK} spacing="md">
      <Grid item xs={12} md={5} lg={4} className={`${BLOCK}__picture`}>
        <img src={about} alt="About Me" />
      </Grid>
      <Grid item xs={12} md={7} lg={8}>
        <Markdown className={`${BLOCK}__description`}>
          {ABOUT_DESCRIPTION}
        </Markdown>
      </Grid>
    </Grid>
  );
}

export default PageAbout;
