import React from "react";
import cx from "classnames";
import { Outlet, Link, useLocation } from "react-router-dom";

import logo from "assets/images/logo.png";
import instagram from "assets/images/icons/instagram.png";

import "./layout.scss";

import { HINOV_ROUTE_URL } from "constants/app.constants";
import Grid from "components/grid/Grid";
import useEventListener from "hooks/use-event-listener.hook";
import { getIsActiveLink, NAV_ITEMS } from "./layout.utils";

const BLOCK = "layout";

const Layout: React.FC = () => {
  const [showScroll, setShowScroll] = React.useState(false);
  const location = useLocation();

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  React.useEffect(scrollTop, [location]);

  useEventListener("scroll", () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  });

  return (
    <div className={BLOCK}>
      <div className={`${BLOCK}__content`}>
        <Header />
        <Outlet />
      </div>
      <Footer />
      <button
        className={cx(`${BLOCK}__scroll-top`, {
          [`${BLOCK}__scroll-top--is-visible`]: showScroll,
        })}
        onClick={scrollTop}
      />
    </div>
  );
};

interface HeaderProps {
  isSticky?: boolean;
}

const Header: React.FC<HeaderProps> = ({ isSticky = false }) => {
  const { pathname } = useLocation();
  const classNames = cx(`${BLOCK}__header`, {
    [`${BLOCK}__header--is-sticky`]: isSticky,
  });

  useEventListener("click", (e) => {
    const toggleButton = document.querySelector("[data-toggle-button]");
    if (e.target === toggleButton) {
      toggleButton?.classList.toggle("active");
    } else {
      toggleButton?.classList.remove("active");
    }
  });

  return (
    <Grid container as="header" className={classNames} spacing="md">
      <Grid item className={`${BLOCK}__header__content`} xs={12}>
        <Link to={HINOV_ROUTE_URL} className={`${BLOCK}__brand-logo`}>
          <img src={logo} alt="Hinov Studio" />
        </Link>
        <button className={`${BLOCK}__toggle-button`} data-toggle-button />
        <ul className={`${BLOCK}__navbar`}>
          {NAV_ITEMS.map((item) => {
            if (item.isExternal) {
              return (
                <li className={item.className} key={item.to}>
                  <a href={item.to} target="_blank" rel="noreferrer">
                    {item.label}
                  </a>
                </li>
              );
            }

            const isActive = getIsActiveLink(pathname, item);

            return (
              <li key={item.to}>
                <Link to={item.to} className={isActive ? "active" : ""}>
                  {item.label}
                </Link>
              </li>
            );
          })}
          <li className="instagram-logo">
            <a
              href="https://www.instagram.com/hinov_studio"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram} alt="instagram" />
            </a>
          </li>
        </ul>
      </Grid>
    </Grid>
  );
};

const Footer: React.FC = () => (
  <footer className={`${BLOCK}__footer`}>
    <p>© 2022 — HINOV STUDIO</p>
  </footer>
);

export default Layout;
