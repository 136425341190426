import type { Data } from "types/app.types";

import projectAmbert from "./project-ambert.constants";
import projectCaravelle from "./project-caravelle.constants";
import projectDandy from "./project-dandy.constants";
import projectFrancisco from "./project-francisco.constants";
import projectGiroflees from "./project-giroflees.constants";
import projectHinov from "./project-hinov.constants";
import projectVictor from "./project-victor.constants";

const ROOT_PATH = "";
export const HINOV_ROUTE_URL = `${ROOT_PATH}/`;
export const HINOV_PROJECTS_ROUTE_URL = `${ROOT_PATH}/projects`;
export const HINOV_ABOUT_ROUTE_URL = `${ROOT_PATH}/about`;
export const HINOV_CONTACT_ROUTE_URL = `${ROOT_PATH}/contact`;

const data: Data = {
  projects: [
    projectHinov,
    projectCaravelle,
    projectDandy,
    projectGiroflees,
    projectAmbert,
    projectVictor,
    projectFrancisco,
  ],
};

export function getProjects() {
  return data.projects;
}
