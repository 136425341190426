import type { ProjectData } from "types/app.types";

import caravelle00 from "assets/images/projects/caravelle/caravelle-00.jpg";
import caravelle01 from "assets/images/projects/caravelle/caravelle-01.jpg";
import caravelle02 from "assets/images/projects/caravelle/caravelle-02.jpg";
import caravelle03 from "assets/images/projects/caravelle/caravelle-03.jpg";
import caravelle04 from "assets/images/projects/caravelle/caravelle-04.jpg";
import caravelle05 from "assets/images/projects/caravelle/caravelle-05.jpg";
import caravelle06 from "assets/images/projects/caravelle/caravelle-06.jpg";
import caravelle07 from "assets/images/projects/caravelle/caravelle-07.jpg";
import caravelle08 from "assets/images/projects/caravelle/caravelle-08.jpg";
import caravelle09 from "assets/images/projects/caravelle/caravelle-09.jpg";
import caravelle10 from "assets/images/projects/caravelle/caravelle-10.jpg";
import caravelle11 from "assets/images/projects/caravelle/caravelle-11.jpg";
import caravelle12 from "assets/images/projects/caravelle/caravelle-12.jpg";
import caravelle13 from "assets/images/projects/caravelle/caravelle-13.jpg";
import caravelle14 from "assets/images/projects/caravelle/caravelle-14.jpg";
import caravelle15 from "assets/images/projects/caravelle/caravelle-15.jpg";
import caravelle16 from "assets/images/projects/caravelle/caravelle-16.jpg";
import caravelle17 from "assets/images/projects/caravelle/caravelle-17.jpg";
import caravelle18 from "assets/images/projects/caravelle/caravelle-18.jpg";
import caravelle19 from "assets/images/projects/caravelle/caravelle-19.png";
import caravelle20 from "assets/images/projects/caravelle/caravelle-20.png";
import caravelle21 from "assets/images/projects/caravelle/caravelle-21.png";

const project: ProjectData = {
  id: "caravelle",
  title: "Appartement Caravelle",
  attributes: [
    ["lieu", "Cabourg"],
    ["surface", "41 m²"],
    ["programme", "Rénovation complète d’un appartement"],
    ["mission", "Mission complète, conception et maîtrise d’œuvre, suivi de travaux"],
    ["statut", "Livré en avril 2021"],
  ],
  description: `L’objectif de cette rénovation a été d’optimiser les surfaces de ce grand studio en recloisonnant les volumes. L’appartement ne disposait pas de chambres mais d’un double séjour, délimité par une cloison pliante en PVC.

Dans notre projet, l’alcôve formée par une estrade en contreplaqué de peuplier est fermée par des rideaux en velours permettant de recréer un coin nuit intimiste et chaleureux.

Cet agencement crée des rangements astucieux accessibles sur les côtés du lit et par de grands tiroirs.

L’espace a été pensé afin de conserver un maximum de luminosité et de transparence.

La chambre dispose d’une porte vitrée laissant entrevoir la fenêtre, dans le même esprit du double séjour avant travaux.

La cloison de la cuisine a été démolie permettant de recréer un espace de séjour convenable malgré le cloisonnement de la chambre.

Les détails de la salle d’eau, telle la tablette en faïence où repose un grand miroir doré, lui apportent du cachet et nous font oublier sa petite surface.

En journée, les rideaux ouverts de l’alcôve permettent de conserver un sentiment d’espace.`,
  imageSrc: caravelle00,
  slides: [
    [caravelle01],
    [caravelle02, caravelle03],
    [caravelle04, caravelle05],
    [caravelle06, caravelle07],
    [caravelle08],
    [caravelle09, caravelle10],
    [caravelle11, caravelle12],
    [caravelle13, caravelle14],
    [caravelle15],
    [caravelle16, caravelle17],
    [caravelle18],
    [caravelle19],
    [caravelle20, caravelle21, "true", "true"],
  ],
};

export default project;
