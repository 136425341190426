import type { ProjectData } from "types/app.types";

import francisco00 from "assets/images/projects/francisco/francisco-00.jpg";
import francisco01 from "assets/images/projects/francisco/francisco-01.jpg";
import francisco02 from "assets/images/projects/francisco/francisco-02.jpg";
import francisco03 from "assets/images/projects/francisco/francisco-03.jpg";
import francisco04 from "assets/images/projects/francisco/francisco-04.jpg";
import francisco05 from "assets/images/projects/francisco/francisco-05.jpg";
import francisco06 from "assets/images/projects/francisco/francisco-06.jpg";
import francisco07 from "assets/images/projects/francisco/francisco-07.png";
import francisco08 from "assets/images/projects/francisco/francisco-08.png";

const project: ProjectData = {
  id: "francisco",
  title: "Maison Francisco Ferrer",
  attributes: [
    ["lieu", "Bagnolet"],
    ["surface", "21 m²"],
    ["programme", "Rénovation d’une cuisine"],
    ["mission", "Conception"],
    ["statut", "Livré en juin 2021"],
  ],
  description: `La cuisine existante n’était pas assez fonctionnelle car elle s’orientait vers le mur de façade côté entrée ce qui laissait une large zone non occupée entre les sanitaires et le palier de l’escalier.

L’entrée de la maison donne directement sur le séjour et la cuisine ouverte, il était donc important que celle-ci soit mise en valeur par un grand îlot central décoré par de jolies suspensions tombant au-dessus du plan de travail.

L’îlot a permis de recentrer la cuisine et ne laisser que des espaces de circulations tout autour, ce qui donne un espace convivial.`,
  imageSrc: francisco00,
  slides: [
    [francisco01, francisco02],
    [francisco03, francisco04],
    [francisco05, francisco06],
    [francisco07],
    [francisco08],
  ],
};

export default project;
