import React from "react";

import "./project-slide.scss";

const BLOCK = "project-slide";

interface Props {
  imageSrc: string;
  imageAlt: string;
  onClick: () => void;
}

const ProjectSlide: React.FC<Props> = ({ imageSrc, imageAlt, onClick }) => {
  return (
    <div className={BLOCK} onClick={onClick}>
      <img className={`${BLOCK}__image`} src={imageSrc} alt={imageAlt} />
    </div>
  );
};

export default ProjectSlide;
