import type { ProjectData } from "types/app.types";

import ambert00 from "assets/images/projects/ambert/ambert-00.jpg";
import ambert01 from "assets/images/projects/ambert/ambert-01.jpg";
import ambert02 from "assets/images/projects/ambert/ambert-02.jpg";
import ambert03 from "assets/images/projects/ambert/ambert-03.jpg";
import ambert04 from "assets/images/projects/ambert/ambert-04.jpg";
import ambert05 from "assets/images/projects/ambert/ambert-05.jpg";
import ambert06 from "assets/images/projects/ambert/ambert-06.jpg";
import ambert07 from "assets/images/projects/ambert/ambert-07.jpg";
import ambert08 from "assets/images/projects/ambert/ambert-08.jpg";
import ambert09 from "assets/images/projects/ambert/ambert-09.png";

const project: ProjectData = {
  id: "ambert",
  title: "Appartement Clos d’Ambert",
  attributes: [
    ["lieu", "Noisy-le-Grand"],
    ["surface", "5 m²"],
    ["programme", "Rénovation d’une cuisine"],
    [
      "mission",
      "Mission complète, conception et maîtrise d’œuvre, suivi de travaux",
    ],
    ["statut", "Livré en juillet 2021"],
  ],
  description: `Cette cuisine ouverte a été pensée afin d’offrir un maximum d’espace malgré sa petite surface. La réalisation d’un bar en retour de plan de travail permet de créer une table haute pour prendre un repas sur le pouce.

La faïence rose clair avec joints noirs lui donne un côté glamour accentué par les fauteuils de bar en pied-de-poule.

En opposition, des plantes tombantes sont suspendues au-dessus de l’évier lui donnant un aspect verdoyant.

Des illustrations vintages inspirées de l’univers japonais procurent au lieu son style particulier.`,
  imageSrc: ambert00,
  slides: [
    [ambert01],
    [ambert02, ambert03],
    [ambert04, ambert05],
    [ambert06, ambert07],
    [ambert08],
    [ambert09],
  ],
};

export default project;
