import type { ProjectData } from "types/app.types";

import giroflees00 from "assets/images/projects/giroflees/giroflees-00.jpg";
import giroflees01 from "assets/images/projects/giroflees/giroflees-01.jpg";
import giroflees02 from "assets/images/projects/giroflees/giroflees-02.jpg";
import giroflees03 from "assets/images/projects/giroflees/giroflees-03.jpg";
import giroflees04 from "assets/images/projects/giroflees/giroflees-04.jpg";
import giroflees05 from "assets/images/projects/giroflees/giroflees-05.jpg";
import giroflees06 from "assets/images/projects/giroflees/giroflees-06.jpg";
import giroflees07 from "assets/images/projects/giroflees/giroflees-07.jpg";
import giroflees08 from "assets/images/projects/giroflees/giroflees-08.jpg";
import giroflees09 from "assets/images/projects/giroflees/giroflees-09.jpg";
import giroflees10 from "assets/images/projects/giroflees/giroflees-10.jpg";
import giroflees11 from "assets/images/projects/giroflees/giroflees-11.jpg";
import giroflees12 from "assets/images/projects/giroflees/giroflees-12.jpg";
import giroflees13 from "assets/images/projects/giroflees/giroflees-13.jpg";
import giroflees14 from "assets/images/projects/giroflees/giroflees-14.jpg";
import giroflees15 from "assets/images/projects/giroflees/giroflees-15.jpg";
import giroflees16 from "assets/images/projects/giroflees/giroflees-16.jpg";
import giroflees17 from "assets/images/projects/giroflees/giroflees-17.jpg";
import giroflees18 from "assets/images/projects/giroflees/giroflees-18.png";

const project: ProjectData = {
  id: "giroflees",
  title: "Maison Giroflées",
  attributes: [
    ["lieu", "Noisy-le-Grand"],
    ["surface", "15 m²"],
    ["programme", "Création d’un bureau et rénovation d’une cuisine"],
    [
      "mission",
      "Mission complète, conception et maîtrise d’œuvre, suivi de travaux",
    ],
    ["statut", "Livré en mars 2015"],
  ],
  description: `Le premier étage de cette maison disposait d’un grand palier dont l’usage était réduit par le rampant du toit.

Pour ce projet il a fallu imaginer un agencement permettant de créer deux espaces de bureau réunies par une banquette centrale.

L’utilisation du contreplaqué de bouleau qui est un bois clair permet d’offrir une impression de clarté et de luminosité malgré le petit espace.

Des rangements ont été pensés sur toutes les faces du meuble afin d’optimiser au maximum la création de placards et tiroirs.

La cuisine ouverte quant-à-elle conserve le même esprit chaleureux par l’emploi de contreplaqué de pin pour le plan de travail et les meubles hauts.

Celui-ci possède un veinage plus marqué que le bouleau, ce qui apporte du caractère à l’espace.`,
  imageSrc: giroflees00,
  slides: [
    [giroflees01],
    [giroflees02, giroflees03],
    [giroflees04],
    [giroflees05, giroflees06],
    [giroflees07, giroflees08],
    [giroflees09, giroflees10],
    [giroflees11, giroflees12],
    [giroflees13, giroflees14],
    [giroflees15],
    [giroflees16],
    [giroflees17],
    [giroflees18],
  ],
};

export default project;
