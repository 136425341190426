import React from "react";
import cx from "classnames";

import "./grid.scss";

import type {
  AlignItems,
  Cols,
  JustifyContent,
  Spacing,
} from "types/app.types";

const BLOCK = "grid";

interface Props {
  children: React.ReactNode;
  className?: string;
  container?: boolean;
  item?: boolean;
  as?: React.ElementType;
  xs?: Cols;
  sm?: Cols;
  md?: Cols;
  lg?: Cols;
  xl?: Cols;
  spacing?: Spacing;
  justify?: JustifyContent;
  alignItems?: AlignItems;
}

const Grid: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  container,
  item,
  as: TagName = "div",
  xs,
  sm,
  md,
  lg,
  xl,
  spacing,
  justify,
  alignItems,
  ...rest
}) => {
  const classNames = cx(BLOCK, className, {
    [`${BLOCK}-container`]: container,
    [`${BLOCK}-item`]: item,
    [`${BLOCK}-xs-${xs}`]: xs,
    [`${BLOCK}-sm-${sm}`]: sm,
    [`${BLOCK}-md-${md}`]: md,
    [`${BLOCK}-lg-${lg}`]: lg,
    [`${BLOCK}-xl-${xl}`]: xl,
    [`${BLOCK}-spacing-${spacing}`]: spacing,
    [`${BLOCK}-justify-${justify}`]: justify,
    [`${BLOCK}-align-${alignItems}`]: alignItems,
  });

  return (
    <TagName className={classNames} {...rest}>
      {children}
    </TagName>
  );
};

export default Grid;
