import { useCallback } from "react";

import useEventListener from "./use-event-listener.hook";

const useKeydownEvent = (key: string, callback: () => void) => {
  const handler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key !== key) {
        return;
      }
      callback();
    },
    [key, callback]
  );

  useEventListener("keydown", handler);
};

export default useKeydownEvent;
