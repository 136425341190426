import type { ProjectData } from "types/app.types";

export const getProjectSlides = (project: ProjectData) =>
  project.slides.reduce(
    (aggr: { src: string; id: string }[], cur, slideId) => [
      ...aggr,
      ...cur.slice(0, 2).map((image, imageIndex) => ({
        src: image,
        id: `${slideId}-${imageIndex}`,
      })),
    ],
    []
  );
