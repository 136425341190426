import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import "./page-project.scss";

import { getProjects, HINOV_PROJECTS_ROUTE_URL } from "constants/app.constants";
import useKeydownEvent from "hooks/use-keydown-event.hook";
import Project from "components/project/Project";
import ProjectSlide from "components/project-slide/ProjectSlide";
import ProjectSlider from "components/project-slider/ProjectSlider";
import Grid from "components/grid/Grid";
import { NoMatch } from "App";

const BLOCK = "page-project";
const SHOW_SLIDER = true;

function PageProject() {
  const [slideId, setSlideId] = React.useState<string>();
  const { projectId } = useParams();
  const navigate = useNavigate();

  const closeSlider = () => setSlideId(undefined);

  const projects = getProjects();
  const projectIndex = projects.findIndex(({ id }) => id === projectId);
  const project = projects[projectIndex];

  const prevProject = React.useCallback(() => {
    if (slideId) return;
    const prevProjectIndex =
      projectIndex === 0 ? projects.length - 1 : projectIndex - 1;
    navigate(`${HINOV_PROJECTS_ROUTE_URL}/${projects[prevProjectIndex].id}`);
  }, [slideId, navigate, projects, projectIndex]);

  const nextProject = React.useCallback(() => {
    if (slideId) return;
    const nextProjectIndex =
      projectIndex === projects.length - 1 ? 0 : projectIndex + 1;
    navigate(`${HINOV_PROJECTS_ROUTE_URL}/${projects[nextProjectIndex].id}`);
  }, [slideId, navigate, projects, projectIndex]);

  const onSlideClick = (id: string) => setSlideId(id);

  useKeydownEvent("Escape", () => setTimeout(closeSlider, 450));
  useKeydownEvent("ArrowLeft", prevProject);
  useKeydownEvent("ArrowRight", nextProject);

  if (!project) {
    return <NoMatch />;
  }

  return (
    <Grid container className={BLOCK} spacing="md">
      <div className={`${BLOCK}__project-navigation`}>
        <button className="prev" onClick={prevProject} />
        <button className="next" onClick={nextProject} />
      </div>

      <Grid item xs={12}>
        <Project
          id={project.id}
          title={project.title}
          attributes={project.attributes}
          description={project.description}
          isInteractive={false}
        />
      </Grid>

      {project.slides.map((slide, slideId) => {
        if (slide.length === 4) {
          return (
            <Grid key={slideId} item xs={12} className={`${BLOCK}__slide-flex`}>
              {slide.slice(0, 2).map((src, imageId) => (
                <ProjectSlide
                  key={`${slideId}-${imageId}`}
                  imageSrc={src}
                  imageAlt={project.title}
                  onClick={() => onSlideClick(`${slideId}-${imageId}`)}
                />
              ))}
            </Grid>
          );
        }
        return slide.slice(0, 3).map((src, imageId) => {
          const id = `${slideId}-${imageId}`;
          return (
            <Grid key={id} item xs={12} lg={slide.length === 1 ? 12 : slide.length === 2 ? 6 : 4}>
              <ProjectSlide
                imageSrc={src}
                imageAlt={project.title}
                onClick={() => onSlideClick(id)}
              />
            </Grid>
          );
        });
      })}

      {SHOW_SLIDER && slideId && (
        <ProjectSlider
          project={project}
          slideId={slideId}
          onClose={closeSlider}
        />
      )}
    </Grid>
  );
}

export default PageProject;
