import React from "react";
import cx from "classnames";
import Markdown from "react-markdown";
import { useNavigate } from "react-router-dom";

import "./project.scss";

import type { Attribute } from "types/app.types";
import { HINOV_PROJECTS_ROUTE_URL } from "constants/app.constants";

const BLOCK = "project";

export interface ProjectProps {
  id: string;
  title?: string;
  subtitle?: string;
  attributes?: Attribute[];
  description?: string;
  imageSrc?: string;
  imageAlt?: string;
  isInteractive?: boolean;
}

const Project: React.FC<ProjectProps> = ({
  id,
  title,
  subtitle,
  attributes = [],
  description,
  imageSrc,
  imageAlt,
  isInteractive = true,
}) => {
  const navigate = useNavigate();

  const onClick = () => {
    if (!isInteractive) return;
    navigate(`${HINOV_PROJECTS_ROUTE_URL}/${id}`);
  };

  return (
    <div
      className={cx(BLOCK, { [`${BLOCK}--is-interactive`]: isInteractive })}
      onClick={onClick}
    >
      {title && <h2 className={`${BLOCK}__title`}>{title}</h2>}
      {subtitle && <h3 className={`${BLOCK}__subtitle`}>{subtitle}</h3>}

      {(attributes.length > 0 || description) && (
        <div className={`${BLOCK}__details`}>
          {attributes.length > 0 && (
            <div className={`${BLOCK}__attributes`}>
              {attributes.map(([key, value], i) => (
                <React.Fragment key={i}>
                  <p
                    className={cx(`${BLOCK}__attributes__key`, {
                      last: i === attributes.length - 1,
                    })}
                  >
                    {key}
                  </p>
                  <p
                    className={cx(`${BLOCK}__attributes__value`, {
                      last: i === attributes.length - 1,
                    })}
                  >
                    {value}
                  </p>
                </React.Fragment>
              ))}
            </div>
          )}

          {description && (
            <Markdown className={`${BLOCK}__description`}>
              {description}
            </Markdown>
          )}
        </div>
      )}

      {imageSrc && (
        <div className={`${BLOCK}__image-wrapper`}>
          <img
            className={`${BLOCK}__image`}
            src={imageSrc}
            alt={imageAlt || title}
          />
          <div>
            <p>Voir</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Project;
