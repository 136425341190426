import React from "react";
import Grid from "components/grid/Grid";

import "./page-projects.scss";

import { getProjects } from "constants/app.constants";
import Project from "components/project/Project";

const BLOCK = "page-projects";

function PageProjects() {
  const projects = getProjects();

  return (
    <Grid container className={BLOCK} spacing="md">
      {projects.map((project, index) => {
        let subtitle = project.title;
        const location = project.attributes.find(([key]) => key === "lieu");
        if (location) subtitle += ` — ${location[1]}`;

        return (
          <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
            <Project
              id={project.id}
              subtitle={subtitle}
              imageSrc={project.imageSrc}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default PageProjects;
