export const ABOUT_DESCRIPTION = `Passionnée par la création et les arts depuis son jeune âge, Roselyne Hin Vo intègre l’Ecole Nationale Supérieure d’Architecture de Paris-La Villette (ENSAPLV) en 2006.

Diplômée en 2009, elle poursuit son parcours en agence pendant plus de 10 ans et devient rapidement chef de projets pour des opérations de tous types : réhabilitation de logements, bâtiments tertiaires, établissements recevant du public dont des hôpitaux, maisons de retraite et centre d’hébergement.

Son rôle majeur en tant que maître d’œuvre en suivi de travaux, lui permet de comprendre les enjeux techniques des projets sur lesquels elle travaille et d’anticiper au maximum les aléas de chantier.

Progressivement, elle ressent le besoin de se focaliser sur des missions combinant à la fois de la conception architecturale et de la décoration intérieure, lui permettant ainsi d’exprimer pleinement son sens du détail.

En 2019, elle décide donc de s’inscrire à l’ordre des Architectes et de fonder sa propre agence HINOV STUDIO.

Elle propose aujourd’hui des prestations comprenant des missions complètes sur des opérations des plus classiques aux plus complexes, pouvant impliquer des détails structurels.

Ses expériences diverses lui permettent d’appréhender des projets de toutes tailles, pour des particuliers, hôteliers, restaurateurs, et professionnels.

En alliant un style à la fois contemporain et ancien, elle adore concevoir des agencements sur mesure et intégrer des pièces uniques chinées qui apportent du caractère à un intérieur.

Depuis quelques années, elle se rend compte de l’importance du végétal dans notre quotidien et de l’inspiration qu’il nous apporte. Dès que le projet s’y prête, elle tend à le mettre au cœur de sa réflexion.

Son univers est un mélange de styles qui permet de créer des espaces singuliers répondant aux exigences de chacun.`;
