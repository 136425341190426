import type { ProjectData } from "types/app.types";

import dandy00 from "assets/images/projects/dandy/dandy-00.jpg";
import dandy01 from "assets/images/projects/dandy/dandy-01.jpg";
import dandy02 from "assets/images/projects/dandy/dandy-02.jpg";
import dandy03 from "assets/images/projects/dandy/dandy-03.jpg";
import dandy04 from "assets/images/projects/dandy/dandy-04.jpg";
import dandy05 from "assets/images/projects/dandy/dandy-05.jpg";
import dandy06 from "assets/images/projects/dandy/dandy-06.jpg";
import dandy07 from "assets/images/projects/dandy/dandy-07.jpg";
import dandy08 from "assets/images/projects/dandy/dandy-08.jpg";
import dandy09 from "assets/images/projects/dandy/dandy-09.jpg";
import dandy10 from "assets/images/projects/dandy/dandy-10.jpg";
import dandy11 from "assets/images/projects/dandy/dandy-11.jpg";
import dandy12 from "assets/images/projects/dandy/dandy-12.jpg";
import dandy13 from "assets/images/projects/dandy/dandy-13.jpg";
import dandy14 from "assets/images/projects/dandy/dandy-14.jpg";
import dandy15 from "assets/images/projects/dandy/dandy-15.jpg";
import dandy16 from "assets/images/projects/dandy/dandy-16.jpg";
import dandy17 from "assets/images/projects/dandy/dandy-17.jpg";
import dandy18 from "assets/images/projects/dandy/dandy-18.jpg";
import dandy19 from "assets/images/projects/dandy/dandy-19.jpg";
import dandy20 from "assets/images/projects/dandy/dandy-20.jpg";
import dandy21 from "assets/images/projects/dandy/dandy-21.jpg";
import dandy22 from "assets/images/projects/dandy/dandy-22.jpg";
import dandy23 from "assets/images/projects/dandy/dandy-23.jpg";
import dandy24 from "assets/images/projects/dandy/dandy-24.jpg";
import dandy25 from "assets/images/projects/dandy/dandy-25.jpg";
import dandy26 from "assets/images/projects/dandy/dandy-26.jpg";
import dandy27 from "assets/images/projects/dandy/dandy-27.jpg";
import dandy28 from "assets/images/projects/dandy/dandy-28.jpg";
import dandy29 from "assets/images/projects/dandy/dandy-29.jpg";
import dandy30 from "assets/images/projects/dandy/dandy-30.jpg";
import dandy31 from "assets/images/projects/dandy/dandy-31.jpg";
import dandy32 from "assets/images/projects/dandy/dandy-32.jpg";
import dandy33 from "assets/images/projects/dandy/dandy-33.jpg";
import dandy34 from "assets/images/projects/dandy/dandy-34.png";
import dandy35 from "assets/images/projects/dandy/dandy-35.png";

const project: ProjectData = {
  id: "dandy",
  title: "Dandy Hotel & Kitchen",
  attributes: [
    ["lieu", "Paris 1er"],
    ["surface", "1 395 m²"],
    [
      "programme",
      "Création d’un hôtel 4 étoiles de 36 chambres avec restaurant, saunas et salle de massage",
    ],
    [
      "mission",
      "Mission complète, conception et maîtrise d’œuvre, suivi de travaux en qualité d’architecte HMONP",
    ],
    ["décorateur", "Maison Malapert"],
    ["statut", "Livré en février 2022"],
    ["photos", "Nicolas Anetson"],
  ],
  description: `Hinov Studio a travaillé en association avec l’agence Gazzola sur cette belle opération d’hôtel restaurant situé au cœur de Paris.

Le Dandy Hôtel est un projet complexe où il a fallu travailler sur 3 immeubles différents abritant à la fois de l’habitation et des activités commerciales.

En tenant compte des contraintes propres à chacun, il a fallu composer avec les copropriétés, le partage des cours permettant l’apport de lumière mais également gérer les altimétries des bâtiments qui créent ce cheminement particulier dans les étages.

On zigzague dans les couloirs, on monte et on descend les marches, mais c’est ce qui apporte du charme à cet hôtel typique parisien.

Le rez-de-chaussée accueille un restaurant sur rue ainsi qu’un lobby situé au fond de l’hôtel.

Ce dernier a été astucieusement mis en valeur avec la création d’une nouvelle façade marquée par un store intérieur reprenant les mêmes codes que celui sur rue et permettant d’identifier clairement les 2 zones.

Les chambres ont été réparties sur trois étages et offrent des espaces singuliers liés à la configuration des lieux qui créent entre autres des salles de bain et verrières de toutes tailles, et un agencement sur mesure intelligemment designé par la Maison Malapert.`,
  imageSrc: dandy00,
  slides: [
    [dandy01],
    [dandy02],
    [dandy03, dandy04],
    [dandy05],
    [dandy06],
    [dandy07],
    [dandy08],
    [dandy09, dandy10],
    [dandy11],
    [dandy12],
    [dandy13],
    [dandy14],
    [dandy15],
    [dandy16],
    [dandy17],
    [dandy18],
    [dandy19, dandy20],
    [dandy21],
    [dandy22],
    [dandy23, dandy24],
    [dandy25],
    [dandy26],
    [dandy27, dandy28],
    [dandy29],
    [dandy30, dandy31],
    [dandy32, dandy33],
    [dandy34],
    [dandy35],
  ],
};

export default project;
