import type { ProjectData } from "types/app.types";

import victor00 from "assets/images/projects/victor/victor-00.jpg";
import victor01 from "assets/images/projects/victor/victor-01.jpg";
import victor02 from "assets/images/projects/victor/victor-02.jpg";
import victor03 from "assets/images/projects/victor/victor-03.jpg";
import victor04 from "assets/images/projects/victor/victor-04.jpg";
import victor05 from "assets/images/projects/victor/victor-05.jpg";
import victor06 from "assets/images/projects/victor/victor-06.jpg";
import victor07 from "assets/images/projects/victor/victor-07.jpg";
import victor08 from "assets/images/projects/victor/victor-08.jpg";
import victor09 from "assets/images/projects/victor/victor-09.jpg";
import victor10 from "assets/images/projects/victor/victor-10.jpg";
import victor11 from "assets/images/projects/victor/victor-11.jpg";
import victor12 from "assets/images/projects/victor/victor-12.jpg";
import victor13 from "assets/images/projects/victor/victor-13.png";

const project: ProjectData = {
  id: "victor",
  title: "Maison Victor Hugo",
  attributes: [
    ["lieu", "Emerainville"],
    ["surface", "90 m²"],
    ["programme", "Rénovation complète d’une maison"],
    ["mission", "Conception"],
    ["statut", "Livré en mars 2022"],
  ],
  description: `Dans cette maison toute en longueur, l’objectif a été de décloisonner l’espace afin d’agrandir les volumes.

La circulation principale a été supprimée permettant d’apporter une double orientation et un apport de lumière plus important et de créer des séquences de vie tirant partie d’un espace étroit et long.`,
  imageSrc: victor00,
  slides: [
    [victor01, victor02],
    [victor03, victor04],
    [victor05, victor06],
    [victor07, victor08],
    [victor09, victor10],
    [victor11, victor12],
    [victor13],
  ],
};

export default project;
