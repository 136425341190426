import {
  HINOV_ROUTE_URL,
  HINOV_PROJECTS_ROUTE_URL,
  HINOV_ABOUT_ROUTE_URL,
  HINOV_CONTACT_ROUTE_URL,
} from "constants/app.constants";

interface NavItem {
  to: string;
  label: string;
  isProject?: boolean;
  isExternal?: boolean;
  className?: string;
}

export const NAV_ITEMS: NavItem[] = [
  { to: HINOV_ROUTE_URL, label: "Projets", isProject: true },
  { to: HINOV_ABOUT_ROUTE_URL, label: "À propos" },
  { to: HINOV_CONTACT_ROUTE_URL, label: "Contact" },
  {
    to: "https://www.instagram.com/hinov_studio",
    label: "Instagram",
    className: "instagram-link",
    isExternal: true,
  },
];

export const getIsActiveLink = (pathname: string, item: NavItem): boolean => {
  if (item.isProject) {
    return (
      pathname === HINOV_ROUTE_URL ||
      pathname.startsWith(HINOV_PROJECTS_ROUTE_URL)
    );
  }
  return pathname === item.to;
};
