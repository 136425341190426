import React from "react";
import { Routes, Route } from "react-router-dom";

import { HINOV_ROUTE_URL } from "constants/app.constants";
import Layout from "components/layout/Layout";
import PageAbout from "components/page-about/PageAbout";
import PageContact from "components/page-contact/PageContact";
import PageProjects from "components/page-projects/PageProjects";
import PageProject from "components/page-project/PageProject";

function App() {
  return (
    <Routes>
      <Route path={HINOV_ROUTE_URL} element={<Layout />}>
        <Route index element={<PageProjects />} />
        <Route path="projects" element={<PageProjects />} />
        <Route path="projects/:projectId" element={<PageProject />} />
        <Route path="about" element={<PageAbout />} />
        <Route path="contact" element={<PageContact />} />
        <Route path="*" element={<NoMatch />} />
      </Route>

      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

export function Home() {
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}

export function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}

export function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
    </div>
  );
}

export function NoMatch() {
  return null; // literally nothing
}

export default App;
