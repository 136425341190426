import React from "react";
import cx from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperClass } from "swiper/types";
import { Autoplay, Navigation /*, Pagination, Scrollbar */ } from "swiper";

import "swiper/scss";
import "swiper/scss/navigation";
// import "swiper/scss/pagination";
// import "swiper/scss/scrollbar";

import "./project-slider.scss";

import type { ProjectData } from "types/app.types";
import useKeydownEvent from "hooks/use-keydown-event.hook";
import { getProjectSlides } from "./project-slider.utils";

const BLOCK = "project-slider";

interface Props {
  project: ProjectData;
  slideId: string;
  onClose: () => void;
}

const ProjectSlider: React.FC<Props> = ({ project, slideId, onClose }) => {
  const [isFading, setIsFading] = React.useState(false);
  const [swiper, setSwiper] = React.useState<SwiperClass>();

  const fadeOut = () => setIsFading(true);

  useKeydownEvent("ArrowLeft", () => swiper?.slidePrev());
  useKeydownEvent("ArrowRight", () => swiper?.slideNext());

  useKeydownEvent("Escape", fadeOut);

  const onCloseButtonClick = () => {
    fadeOut();
    setTimeout(onClose, 450);
  };

  const slides: { src: string; id: string; index: number }[] = getProjectSlides(
    project
  ).map((slide, index) => ({ ...slide, index }));
  const slideIndex = slides.findIndex((s) => s.id === slideId);
  const orderedSlides = [
    ...slides.slice(slideIndex),
    ...slides.slice(0, slideIndex),
  ];

  return (
    <div className={cx(BLOCK, { [`${BLOCK}--is-fading`]: isFading })}>
      <div className={`${BLOCK}__swiper-wrapper`}>
        <button
          className={`${BLOCK}__close-button`}
          onClick={onCloseButtonClick}
        />
        <Swiper
          className={`${BLOCK}__swiper`}
          modules={[Autoplay, Navigation /*, Pagination, Scrollbar */]}
          autoplay={false}
          loop={true}
          slidesPerView={1}
          spaceBetween={10}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
          onSwiper={setSwiper}
          // pagination={{ clickable: true }}
          // scrollbar={{ draggable: true }}
        >
          {orderedSlides.map((slide) => {
            const className = cx(`${BLOCK}__swiper__image`, {
              [project.id]:
                project.id === "francisco" && slide.index < slides.length - 2,
            });
            return (
              <SwiperSlide key={slide.id}>
                <img
                  className={className}
                  src={slide.src}
                  alt={project.title}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="swiper-button-prev" />
        <div className="swiper-button-next" />
      </div>
    </div>
  );
};

export default ProjectSlider;
