import React from "react";
import emailjs from "@emailjs/browser";

import contact from "assets/images/contact.jpg";
import "./page-contact.scss";

import Grid from "components/grid/Grid";

const BLOCK = "page-contact";

type FormStatus = "isLoading" | "failure" | "success";

function PageContact() {
  const [status, setStatus] = React.useState<FormStatus>();

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;

    setStatus("isLoading");
    emailjs.sendForm("gmail", "template_hinov", form, "d_Aqh8RhaIvxcKaVr").then(
      () => {
        setStatus("success");
        form.reset();
      },
      () => {
        setStatus("failure");
      }
    );
  };

  return (
    <Grid container className={BLOCK} spacing="md">
      <Grid item xs={12} className={`${BLOCK}__contact-data`}>
        <h2 className={`${BLOCK}__title`}>Contact</h2>
        <p>HINOV STUDIO</p>
        <p>
          2 bis rue des Giroflées
          <br />
          93160 Noisy-le-Grand
        </p>
        <p>
          <a href="mailto:contact@hinovstudio.com">contact@hinovstudio.com</a>
          <br />
          +33 6 24 09 26 18
        </p>
      </Grid>
      <Grid item xs={12} md={4} className={`${BLOCK}__picture`}>
        <img src={contact} alt="Contact" />
      </Grid>
      <Grid item xs={12} md={8}>
        <form onSubmit={handleFormSubmit} className={`${BLOCK}__form`}>
          <label htmlFor="name">Nom*</label>
          <input type="text" name="name" id="name" required />
          <label htmlFor="email">Email*</label>
          <input type="email" name="email" id="email" required />
          <label htmlFor="phone">Téléphone*</label>
          <input type="tel" name="phone" id="phone" required />
          <label htmlFor="message">Message*</label>
          <textarea name="message" id="message" required />
          <div className={`${BLOCK}__form__footer`}>
            <button type="submit" disabled={status === "isLoading"}>
              Envoyer
            </button>
            {status === "success" && (
              <p className={status}>Votre message a bien été envoyé ✅</p>
            )}
            {status === "failure" && (
              <p className={status}>Une erreur est survenue</p>
            )}
          </div>
        </form>
      </Grid>
    </Grid>
  );
}

export default PageContact;
